'use client';

import styled from '@emotion/styled';
import { useTokens } from '@mezzoforte/forge';

const { breakpoints } = useTokens.huutokaupat;

export type ShowResponsiveProps = Partial<
  Record<'sm' | 'md' | 'lg' | 'xl' | 'smHide' | 'mdHide' | 'lgHide' | 'xlHide', true>
>;

export const ShowResponsive = styled.div<ShowResponsiveProps>`
  display: ${({ smHide, mdHide, lgHide, xlHide }) => (!!smHide || !!mdHide || !!lgHide || !!xlHide ? 'block' : 'none')};

  ${({ sm }) => (sm ? `@media screen and (min-width: ${breakpoints.sm}) { display: block; }` : '')}
  ${({ md }) => (md ? `@media screen and (min-width: ${breakpoints.md}) { display: block; }` : '')}
  ${({ lg }) => (lg ? `@media screen and (min-width: ${breakpoints.lg}) { display: block; }` : '')}
  ${({ xl }) => (xl ? `@media screen and (min-width: ${breakpoints.xl}) { display: block; }` : '')}
  ${({ smHide }) => (smHide ? `@media screen and (min-width: ${breakpoints.sm}) { display: none; }` : '')}
  ${({ mdHide }) => (mdHide ? `@media screen and (min-width: ${breakpoints.md}) { display: none; }` : '')}
  ${({ lgHide }) => (lgHide ? `@media screen and (min-width: ${breakpoints.lg}) { display: none; }` : '')}
  ${({ xlHide }) => (xlHide ? `@media screen and (min-width: ${breakpoints.xl}) { display: none; }` : '')}
`;
