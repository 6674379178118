'use client';

import { datadogRum } from '@datadog/browser-rum-slim';
import { useEffect } from 'react';
import { env } from 'next-runtime-env';
import { useClientRendering } from '@/hooks/useClientRendering';
import { useCookieConsent } from '@/hooks/useCookieConsent';

export function DatadogInit() {
  const isClient = useClientRendering();
  const { cookieConsent } = useCookieConsent();

  useEffect(() => {
    const isProduction = /huutokaupat.com\//.test(window.location.href);

    if (isClient && isProduction && cookieConsent.statistics) {
      datadogRum.init({
        applicationId: '3dcdce55-3c2f-40fc-811e-8cdfa05aa230',
        clientToken: 'pub7e1e55cf25b17736d823820d83140b88',
        site: 'datadoghq.eu',
        service: 'huutokaupat-frontend',
        env: 'prod',
        version: env('NEXT_PUBLIC_GIT_COMMIT'),
        sessionSampleRate: 0.5,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        allowedTracingUrls: ['https://huutokaupat.com'],
      });
    }
  }, [isClient, cookieConsent]);

  return null;
}
