import { Gear, Gavel, SealCheck, Recycle, Flag } from '@mezzoforte/forge-icons';
import { Content, SliceZone } from '@prismicio/client';
import {
  RegistrationIconBlocksSlice,
  RegistrationLinkBlockSlice,
  RegistrationPageDocumentDataSlicesSlice,
} from 'prismicio-types';

/**
 * Add correct icons once Forge-icons updated
 * Gavel, SealCheck, Recycle, Flag
 */
export const registrationInfoIcons = {
  Gear, // default
  Gavel,
  SealCheck,
  Recycle,
  Flag,
} as const;

function isRegistrationIconBlock(
  slice: Content.RegistrationIconBlocksSlice | Content.RegistrationLinkBlockSlice
): slice is Content.RegistrationIconBlocksSlice {
  return (slice as Content.RegistrationIconBlocksSlice).slice_type === 'registration_icon_blocks';
}

function isRegistrationLinkBlock(
  slice: Content.RegistrationIconBlocksSlice | Content.RegistrationLinkBlockSlice
): slice is Content.RegistrationIconBlocksSlice {
  return (slice as Content.RegistrationLinkBlockSlice).slice_type === 'registration_link_block';
}

export function filterRegistrationBlockSlices(slices: SliceZone<RegistrationPageDocumentDataSlicesSlice>) {
  const iconBlocks = slices.filter(isRegistrationIconBlock) as SliceZone<RegistrationIconBlocksSlice>;
  const linkBlocks = slices.filter(isRegistrationLinkBlock) as SliceZone<RegistrationLinkBlockSlice>;
  return { iconBlocks, linkBlocks };
}
