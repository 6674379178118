import React from 'react';
import { Box, Flex } from '@mezzoforte/forge';
import Link from '@/components/Link/Link';
import { Container } from '@/components/Container/Container';

export interface UnreadMessage {
  readonly id: number;
  readonly title: string;
}

export interface UnreadMessageNotificationProps {
  readonly latestUnreadMessage?: UnreadMessage | null;
}

export function UnreadMessageNotification({ latestUnreadMessage }: UnreadMessageNotificationProps) {
  if (!latestUnreadMessage) {
    return null;
  }

  return (
    <Box py={3} backgroundColor="successDark" data-test="unread-message-notification">
      <Container>
        <Flex justifyContent="space-between" fontSize="sm" color="background">
          <Link href={`/oma/tiedotteet/${latestUnreadMessage.id}`}>{latestUnreadMessage.title}</Link>
          <Link href={`/oma/tiedotteet/${latestUnreadMessage.id}`} style={{ textDecoration: 'underline' }}>
            Lue lisää
          </Link>
        </Flex>
      </Container>
    </Box>
  );
}
