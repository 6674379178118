import { useMutation } from '@tanstack/react-query';
import { useApi } from '@/hooks/useApi';

export function useAcceptDsa() {
  const { apiClient } = useApi();

  const mutation = useMutation({
    mutationFn: async ({ companyId, userId }: { companyId: number; userId: number }) => {
      const response = await apiClient.patch<{ success: boolean }>(
        `/api/companies/${companyId}/users/${userId}/accept-dsa`
      );
      return response.data;
    },
  });

  return {
    acceptDsa: mutation,
  };
}
