'use client';

import React from 'react';
import { useTokens } from '@mezzoforte/forge';
import { Global, css } from '@emotion/react';

const { fonts } = useTokens.huutokaupat;

const global = css`
  body {
    --font-family: ${fonts.body};
    --heading-font-family: var(--campton);
    --chakra-fonts-heading: var(--campton);
    --chakra-fonts-button: var(--campton);
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;

    font-family: var(--font-family);
    line-height: 24px;
    margin: 0;
  }

  :where(h1, h2, h3, h4, h5, h6):not(.chakra-heading) {
    font-family: var(--heading-font-family);
    font-weight: var(--chakra-fontWeights-semibold);
    line-height: 1.25;
    margin: 0.75em 0;
  }

  h1 {
    font-size: var(--chakra-fontSizes-3xl);
  }
  h2 {
    font-size: var(--chakra-fontSizes-2xl);
  }
  h3 {
    font-size: var(--chakra-fontSizes-xl);
  }
  h4 {
    font-size: var(--chakra-fontSizes-lg);
  }
  h5 {
    font-size: var(--chakra-fontSizes-md);
  }
  h6 {
    font-size: var(--chakra-fontSizes-sm);
  }

  a.unstyled {
    color: inherit;
    text-decoration: none;

    &:visited. &:hover,
    &:focus,
    &:active {
      color: inherit;
      outline: none;
    }
  }

  @media print {
    .no-print,
    .no-print * {
      display: none !important;
    }
  }

  #CybotCookiebotDialogHeader {
    display: none !important;
  }
`;

export function GlobalStyles() {
  return <Global styles={global} />;
}
