'use client';

import { Box, Flex, FlexProps, BoxProps } from '@mezzoforte/forge';

export function SidebarHeader(props: FlexProps) {
  return (
    <Flex p={3} borderRadius="4px 4px 0px 0px" backgroundColor="backgroundGray" justify="space-between" {...props} />
  );
}

export function SidebarContent(props: BoxProps) {
  return <Box p={3} {...props} />;
}

export function SidebarSection(props: BoxProps) {
  return <Box borderRadius="4px" borderWidth={1} borderColor="lineBorder" overflow="hidden" {...props} />;
}

export function SidebarDivider(props: BoxProps) {
  return <Box mx={-3} mt={3} mb={-3} borderTopWidth={1} borderTopColor="lineBorder" {...props} />;
}
