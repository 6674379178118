'use client';

import dynamic from 'next/dynamic';
import { useSession } from '@/hooks/useSession';
import { useCompanySelection } from '@/hooks/useCompanySelection';

const SellerNavigation = dynamic(() =>
  import('@/components/Header/SellerNavigation').then((module) => module.SellerNavigation)
);
const AdminNavigation = dynamic(() =>
  import('@/components/Header/AdminNavigation').then((module) => module.AdminNavigation)
);
const AccountingNavigation = dynamic(() =>
  import('@/components/Header/AccountingNavigation').then((module) => module.AccountingNavigation)
);

export function Precontent() {
  const {
    currentUser: { data },
  } = useSession();
  const { selectedCompany } = useCompanySelection();

  if (!data?.isAuthenticated) {
    return null;
  }

  const shouldShowSellerNavigation = !data.user.isAdmin && data.user.isSeller && selectedCompany?.isConsumer === false;

  return (
    <>
      {shouldShowSellerNavigation && <SellerNavigation />}
      {!data.user.isAdmin && data.user.isAccounting && <AccountingNavigation />}
      {data.user.isAdmin && <AdminNavigation />}
    </>
  );
}
