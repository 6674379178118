export const DateTimeFormat = (options?: Intl.DateTimeFormatOptions) =>
  Intl.DateTimeFormat('fi-FI', { timeZone: 'Europe/Helsinki', ...options });

export function formatDate(date: Date) {
  return DateTimeFormat().format(date);
}

export function formatDateLong(date: Date) {
  const dateFormatter = DateTimeFormat({
    weekday: 'short',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });

  const timeFormatter = DateTimeFormat({
    hour: 'numeric',
    minute: 'numeric',
  });

  // e.g.: pe 2.6.2023 klo 17.00
  return `${dateFormatter.format(date)} klo ${timeFormatter.format(date)}`;
}

export function formatFromDate(date: Date) {
  const monthFormatter = DateTimeFormat({
    month: 'long',
  });

  const yearFormatter = DateTimeFormat({
    year: 'numeric',
  });

  return `${monthFormatter.format(date)}sta ${yearFormatter.format(date)}`;
}
