'use client';

import React, { useState } from 'react';
import { Box, Button, Flex, Text } from '@mezzoforte/forge';
import { useSession } from '@/hooks/useSession';
import { useCompanySelection } from '@/hooks/useCompanySelection';
import { Container } from '@/components/Container/Container';
import { DsaComplianceMissingModal } from '@/components/Notification/dsa-compliance-missing/DsaComplianceMissingModal';

export interface DsaComplianceMissingNotificationProps {
  readonly isDsaComplianceMissing?: boolean;
}

export function DsaComplianceMissingNotification({ isDsaComplianceMissing }: DsaComplianceMissingNotificationProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { selectedCompany } = useCompanySelection();
  const { currentUser } = useSession();
  const user = currentUser.data?.user;

  const shouldShowDsaNotification = !user?.isAdmin && user?.isSeller && selectedCompany?.isConsumer === false;

  if (!isDsaComplianceMissing || !shouldShowDsaNotification) {
    return null;
  }

  return (
    <>
      <DsaComplianceMissingModal
        isOpen={isModalOpen}
        companyId={selectedCompany.id}
        userId={user.id}
        onDismiss={() => {
          setIsModalOpen(false);
        }}
      />
      <Box
        py={3}
        backgroundColor="successDark"
        data-test="dsa-compliance-mising-notification"
        sx={{
          a: {
            textDecoration: 'underline',
          },
        }}
      >
        <Container>
          <Flex flexDir={{ base: 'column', lg: 'row' }} justifyContent={{ lg: 'space-between' }} alignItems="center">
            <Text color="textInvert">
              Tarvitsemme digipalvelusäädökseen liittyen 16.2.2025 mennessä vahvistuksen siitä, että sitoudut
              noudattamaan kulloinkin voimassa olevia EU:n säädöksiä.
            </Text>
            <Flex justifyContent="center" flexShrink={0} ml={{ base: 0, lg: 3 }} my={{ base: 3, lg: 0 }}>
              <Button onClick={() => setIsModalOpen(true)}>Siirry vahvistamaan</Button>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  );
}
