export const businessIdValidator = {
  isValid(originalId: string, allowVatIdentificationNumber = true): boolean {
    const id = allowVatIdentificationNumber ? this.convertVatNumberToBusinessId(originalId) : originalId;

    if (!/^[0-9]{6,7}-[0-9]$/.test(id)) {
      return false;
    }

    return this.isValidBusinessId(id);
  },
  convertVatNumberToBusinessId(originalId: string): string {
    const upperCaseId = originalId.toUpperCase();

    if (upperCaseId.includes('FI')) {
      const numbers = upperCaseId.replace('FI', '');

      const number = numbers.slice(0, numbers.length - 1);
      const control = numbers.slice(numbers.length - 1);

      return `${number}-${control}`;
    }

    return originalId;
  },
  isValidBusinessId(originalId: string): boolean {
    const [numbers, control] = originalId.split('-');
    const id = numbers.padStart(7, '0').split('').map(Number);

    const check = (id[0] * 7 + id[1] * 9 + id[2] * 10 + id[3] * 5 + id[4] * 8 + id[5] * 4 + id[6] * 2) % 11;

    if (check === 0) {
      return check === Number(control);
    }

    if (check > 1 && check < 11) {
      return 11 - check === Number(control);
    }

    return false;
  },
};
