import { PropsWithChildren } from 'react';
import type { LinkProps } from 'next/link';
import Link from '@/components/Link/Link';
import { isNextjsRoutable, resolve } from '@/util/url';

export function InternalLinkComponent({
  href,
  children,
  ...props
}: { href: string } & PropsWithChildren & Omit<LinkProps, 'href'>) {
  if (isNextjsRoutable(href)) {
    return (
      <Link href={href} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <a href={href} {...props}>
      {children}
    </a>
  );
}

export function ExternalLinkComponent({ href, children, ...props }: React.PropsWithChildren<LinkProps>) {
  // eslint-disable-next-line @typescript-eslint/no-base-to-string
  const hrefString = href.toString();
  try {
    const u = new URL(hrefString);
    if (['huutokaupat.com', 'huutokaupat.dev'].includes(u.host) && isNextjsRoutable(u.pathname)) {
      // Change all "external" links pointing to huutokaupat.com to relative paths
      return (
        <Link href={resolve(hrefString)} {...props} target={undefined} rel={undefined}>
          {children}
        </Link>
      );
    }
    return (
      <a data-external href={resolve(hrefString)} {...props}>
        {children}
      </a>
    );
  } catch {
    return (
      <a data-external href={hrefString} {...props}>
        {children}
      </a>
    );
  }
}
