'use client';

import { useRef, useState } from 'react';
import { Box, Button } from '@mezzoforte/forge';
import { MagnifyingGlass, X } from '@mezzoforte/forge-icons';
import { ShowResponsive } from '@/components/Responsive/ShowResponsive';
import { SearchForm } from '@/features/SiteHeader/SearchForm';

export function HeaderSearchForm({ placeholder }: { placeholder: string }) {
  const [searchVisible, setSearchVisible] = useState(false);
  const input = useRef<HTMLInputElement>(null);

  const toggleSearch = () => {
    setSearchVisible((v) => !v);
    if (!searchVisible) input.current?.focus();
  };

  return (
    <Box>
      <Box
        opacity={{ base: searchVisible ? 1 : 0.5, lg: 1 }}
        position={{ base: 'absolute', lg: 'static' }}
        right="66px"
        left={0}
        transform={{ base: searchVisible ? 'none' : 'translateX(-1000em)', lg: 'none' }}
      >
        <SearchForm
          ref={input}
          placeholder={placeholder}
          width={{
            base: '100%',
            lg: 260,
          }}
        />
      </Box>
      <ShowResponsive lgHide>
        <Button
          backgroundColor="transparent"
          icon={searchVisible ? <X /> : <MagnifyingGlass />}
          aria-label="Näytä haku"
          onClick={toggleSearch}
          data-test="mobile-search-toggle"
          _active={{ background: 'black' }}
          _hover={{ background: 'black' }}
          css={{ '& svg': { fill: 'white' } }}
        />
      </ShowResponsive>
    </Box>
  );
}
