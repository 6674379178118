import { useQuery } from '@tanstack/react-query';
import { ImportedEntries } from '@/components/Notification/imported-entries/ImportedEntriesNotification';
import { UnpaidEntry } from '@/components/Notification/unpaid-entries/UnpaidEntriesNotification';
import { PendingEmailChangeInfo } from '@/components/Notification/pending-email-change/PendingEmailChangeNotification';
import { UnreadMessage } from '@/components/Notification/unread-message/UnreadMessageNotification';
import { VehicleRegistrationEntry } from '@/components/Notification/vehicle-registration/VehicleRegistrationNotification';
import { SystemNotification } from '@/components/Notification/system-notification/SystemMessageNotification';
import { useCompanySelection } from '@/hooks/useCompanySelection';
import { useApi } from '@/hooks/useApi';

export interface NotificationsApiResponse {
  readonly importedEntries: ImportedEntries;
  readonly isBankAccountMissing: boolean;
  readonly unpaidEntries: UnpaidEntry[] | null;
  readonly pendingEmailChange: PendingEmailChangeInfo | null;
  readonly isIdentificationMissing: boolean;
  readonly isDsaComplianceMissing: boolean;
  readonly latestUnreadMessage: UnreadMessage | null;
  readonly vehicleRegistrations: VehicleRegistrationEntry[] | null;
  readonly systemNotification: SystemNotification | null;
}

export const useNotifications = () => {
  const { selectedCompany } = useCompanySelection();
  const { apiClient } = useApi();

  const fetchNotifications = async (companyId?: number) =>
    apiClient
      .get<NotificationsApiResponse>('/api/notifications', { params: { companyId } })
      .then((response) => response.data);

  const notificationsQuery = useQuery({
    queryKey: ['notifications', selectedCompany?.id],
    queryFn: async () => fetchNotifications(selectedCompany?.id),
    staleTime: 1000 * 60,
  });

  return {
    notifications: notificationsQuery,
  };
};
