import React from 'react';
import { Alert, Button, Checkbox, Link, Modal, ModalBody, ModalFooter, ModalHeader } from '@mezzoforte/forge';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useNotifications } from '@/hooks/useNotifications';
import { useAcceptDsa } from '@/hooks/useAcceptDsa';

export interface DsaComplianceMissingModalProps {
  readonly isOpen: boolean;
  readonly companyId: number;
  readonly userId: number;
  readonly onDismiss: () => void;
}

interface Inputs {
  readonly dsaAccepted: boolean;
}

export function DsaComplianceMissingModal({ isOpen, companyId, userId, onDismiss }: DsaComplianceMissingModalProps) {
  const { notifications } = useNotifications();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitSuccessful, isSubmitting, isValid },
  } = useForm<Inputs>({ defaultValues: { dsaAccepted: false } });
  const { acceptDsa } = useAcceptDsa();

  const onSubmit: SubmitHandler<Inputs> = async () => {
    await acceptDsa.mutateAsync(
      { companyId, userId },
      {
        onError: () => {
          setError('root', { type: 'submitError', message: 'Virhe tallennettaessa vahvistusta.' });
        },
      }
    );
  };

  const handleDismiss = () => {
    void notifications.refetch();
    onDismiss();
  };

  return (
    <Modal isOpen={isOpen ?? false} onDismiss={handleDismiss} variant="secondary">
      <ModalHeader>Vahvista sitoumuksesi</ModalHeader>
      {errors.root && (
        <ModalBody>
          <Alert variant="danger" mb={2}>
            {errors.root.message}
          </Alert>
        </ModalBody>
      )}
      {isSubmitSuccessful && (
        <ModalBody>
          <Alert variant="success" mb={2}>
            Vahvistus tallennettu!
          </Alert>
        </ModalBody>
      )}
      {!errors.root && !isSubmitSuccessful && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Controller
              name="dsaAccepted"
              control={control}
              rules={{ required: 'Pakollinen kenttä' }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox onChange={onChange} onBlur={onBlur} checked={value} variant="rowAlignTop">
                  Sitoudun noudattamaan kulloinkin voimassa olevia EU:n säädöksiä ja Suomen lakia sekä tarjoamaan
                  ainoastaan tuotteita tai palveluja, jotka ovat niiden mukaisia. Lue lisää{' '}
                  <Link href="https://www.traficom.fi/fi/ajankohtaista/digitoimijoille-uusia-velvollisuuksia-tavoitteena-turvallisemmat-ja-avoimemmat">
                    EU:n digipalvelusäädöksestä.
                  </Link>
                </Checkbox>
              )}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" variant="highlight" isLoading={isSubmitting} disabled={!isValid}>
              Tallenna vahvistus
            </Button>
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
}
